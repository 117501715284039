import request from '@/plugins/axios'

// 平台配置

// 获取平台信息
export const apiBaseConfig = () => request.get('/settings.platform/getBaseConfig')

// 设置平台信息
export const apiBaseConfigEdit = (params: any) => request.post('/settings.platform/setBaseConfig', params)

// 获取平台备案信息
export const apiRecordConfig = () => request.get('/settings.platform/getRecordConfig')

// 设置平台备案信息
export const apiRecordConfigEdit = (params: any) => request.post('/settings.platform/setRecordConfig', params)

// 系统设置

// 获取系统日志列表
export const apiSystemlogList = (params: any) => request.get('/settings.log/lists', { params })

// 清除系统缓存
export const apiSystemCacheClear = () => request.post('/settings.cache/clear')

// 定时任务列表
export const apiCrontabLists = () => request.get('/crontab.crontab/lists')

// 添加定时任务
export const apiCrontabAdd = (params: any) => request.post('/crontab.crontab/add', params)

// 查看详情
export const apiCrontabDetail = (params: any) => request.get('/crontab.crontab/detail', { params })

// 编辑定时任务
export const apiCrontabEdit = (params: any) => request.post('/crontab.crontab/edit', params)

// 删除定时任务
export const apiCrontabDel = (params: any) => request.post('/crontab.crontab/delete', params)

// 获取规则执行时间
export const apiCrontabExpression = (params: any) => request.get('/crontab.crontab/expression', { params })

// 操作定时任务
export const apiSrontabOperate = (params: any) => request.post('/crontab.crontab/operate', params)

/** E 系统维护 **/

/** S 系统更新 **/
// 系统更新列表
export const apiSystemUpgradeLists = (params: any) => request.get('/settings.upgrade/lists')

// 下载更新包
export const apiSystemUpgradeDownloadPkg = (params: any) => request.post('/settings.upgrade/downloadPkg', params)

// 一键更新
export const apiSystemUpgrade = (params: any) => request.post('/settings.upgrade/upgrade', params)
/** E 系统更新 **/

// 系统环境
export const apiSystemSystemEnv = () => request.get('/settings.env/systemEnv')

/** S 客服设置 **/
// 客服获取
export const apiServiceGet = () => request.get('/settings.service/getConfig')

// 客服设置
export const apiServiceSet = (params: any) => request.post('/settings.service/setConfig', params)
/** E 客服设置 **/

/** S 安全设置 **/
// 修改管理员密码
export const apiResetPassword = (params: any) => request.post('/Login/resetPassword', params)
/** E 安全设置 **/

/** S 存储设置 **/
// 获取存储引擎列表
export const apiStorageList = (): Promise<any> =>
  request.get('/settings.Storage/lists')

// 获取存储配置信息
export const apiStorageIndex = (params: any): Promise<any> =>
  request.get('/settings.Storage/index', { params })

// 更新配置
export const apiStorageSetup = (params: any): Promise<any> =>
  request.post('/settings.Storage/setup', params)

// 切换默认存储引擎
export const apiStorageChange = (params: any): Promise<any> =>
  request.post('/settings.Storage/change', params)
/** E 存储设置 **/

// 支付配置
export const apiPaymentConfigSet = (params: any): Promise<any> =>
  request.post('/settings.pay_config/setConfig', params)

// 获取支付配置
export const apiPaymentConfigGet = (params: any): Promise<any> =>
  request.get('/settings.pay_config/getConfig', { params })

// 获取支付配置的列表
export const apiPaymentConfigGetList = (): Promise<any> =>
  request.get('/settings.pay_config/lists')


// 获取政策协议详情
export const apiProtocolSettingDetails = (params: any) =>
    request.get('/settings.platform/getPolicyAgreement', { params })

// 编辑政策协议
export const apiProtocolSettingSet = (params: any) =>
    request.post('/settings.platform/setPolicyAgreement', params)


/** S 游戏帮助 **/

//帮助设置
export const apiGameHelpConfigSet = (params: any) =>
  request.post('/settings.game_help/setConfig', params)

// 答题设置详情
export const apiGameHelpConfigDetails = (params: any) =>
  request.get('/settings.game_help/getConfig', { params })

// 文章/帮助列表
export const apiGameHelpLists = (params: any) => request.get('/settings.game_help/lists', { params })

// 删除文章/帮助
export const apiGameHelpDelete = (params: any) => request.post('/settings.game_help/delete', params)

// 获取文章/帮助详情
export const apiGameHelpDetail = (params: any) => request.get('/settings.game_help/detail', { params })

// 编辑文章/帮助
export const apiGameHelpEdit = (params: any) => request.post('/settings.game_help/edit', params)

// 添加文章/帮助
export const apiGameHelpAdd = (params: any) => request.post('/settings.game_help/add', params)

// 修改文章/帮助状态
export const apiGameHelpIsShow = (params: any) => request.post('/settings.game_help/isShow', params)

// 分类列表
export const apiGameHelpCategoryLists = (params: any) =>
  request.get('/settings.game_help_category/lists', { params })

// 删除分类
export const apiGameHelpCategoryDelete = (params: any) =>
  request.post('/settings.game_help_category/delete', params)

// 获取分类详情
export const apiGameHelpCategoryDetail = (params: any) =>
  request.get('/settings.game_help_category/detail', { params })

// 编辑分类
export const apiGameHelpCategoryEdit = (params: any) =>
  request.post('/settings.game_help_category/edit', params)

// 添加分类
export const apiGameHelpCategoryAdd = (params: any) => request.post('/settings.game_help_category/add', params)

// 修改分类状态
export const apiGameHelpCategoryIsShow = (params: any) =>
  request.post('/settings.game_help_category/isShow', params)
/** E 游戏帮助 **/


// 穿山甲广告奖励设置详情
export const apiCsjRewardSettingDetails = (params: any) =>
  request.get('/settings.csj_reward_setting/getConfig', { params })

// 穿山甲广告奖励设置保存
export const apiCsjRewardSettingSet = (params: any) =>
  request.post('/settings.csj_reward_setting/setConfig', params)

// 腾讯广告奖励设置详情
export const apiTencentRewardSettingDetails = (params: any) =>
  request.get('/settings.tencent_reward_setting/getConfig', { params })

// 腾讯广告奖励设置保存
export const apiTencentRewardSettingSet = (params: any) =>
  request.post('/settings.tencent_reward_setting/setConfig', params)

// 快手广告奖励设置详情
export const apiKuaishouRewardSettingDetails = (params: any) =>
  request.get('/settings.kuaishou_reward_setting/getConfig', { params })

// 快手广告奖励设置保存
export const apiKuaishouRewardSettingSet = (params: any) =>
  request.post('/settings.kuaishou_reward_setting/setConfig', params)

// 百度广告奖励设置详情
export const apiBaiduRewardSettingDetails = (params: any) =>
  request.get('/settings.baidu_reward_setting/getConfig', { params })

// 百度广告奖励设置保存
export const apiBaiduRewardSettingSet = (params: any) =>
  request.post('/settings.baidu_reward_setting/setConfig', params)


  // 新增答题题目
export const apiGameTopicAdd = (data: any) => request.post('/settings.game_topic/add', data)

// 答题题目列表
export const apiGameTopicLists = (params: any) => request.get('/settings.game_topic/lists', { params })

// 修改答题题目状态
export const apiGameTopicStatus = (data: any) => request.post('/settings.game_topic/status', data)

// 删除答题题目
export const apiGameTopicDel = (data: any) => request.post('/settings.game_topic/del', data)

// 答题题目详情
export const apiGameTopicDetail = (id: number) =>
  request.get('/settings.game_topic/detail', { params: { id } })

// 删除答题题目
export const apiGameTopicEdit = (data: any) => request.post('/settings.game_topic/edit', data)

// 批量导入答题题目
export const apiGameTopicImport = (data: any) => request.post('/settings.game_topic/importTopic', data)


// 新增代码位
export const apiGameCodeBitsAdd = (data: any) => request.post('/settings.game_code_bits/add', data)

// 答题代码位
export const apiGameCodeBitsLists = (params: any) => request.get('/settings.game_code_bits/lists', { params })

// 修改代码位状态
export const apiGameCodeBitsStatus = (data: any) => request.post('/settings.game_code_bits/status', data)

// 删除代码位
export const apiGameCodeBitsDel = (data: any) => request.post('/settings.game_code_bits/del', data)

// 代码位
export const apiGameCodeBitsDetail = (id: number) =>
  request.get('/settings.game_code_bits/detail', { params: { id } })

// 删除代码位
export const apiGameCodeBitsEdit = (data: any) => request.post('/settings.game_code_bits/edit', data)

// 批量导入代码位
export const apiGameCodeBitsImport = (data: any) => request.post('/settings.game_code_bits/import', data)


/**题目分类*/
// 分类列表
export const apiGameTopicCategoryLists = (params: any) =>
  request.get('/settings.game_topic_category/lists', { params })

export const apiGameTopicCategoryAllLists = (params: any) =>
  request.get('/settings.game_topic_category/getAll', { params })

// 删除分类
export const apiGameTopicCategoryDelete = (params: any) =>
  request.post('/settings.game_topic_category/delete', params)

// 获取分类详情
export const apiGameTopicCategoryDetail = (params: any) =>
  request.get('/settings.game_topic_category/detail', { params })

// 编辑分类
export const apiGameTopicCategoryEdit = (params: any) =>
  request.post('/settings.game_topic_category/edit', params)

// 添加分类
export const apiGameTopicCategoryAdd = (params: any) => request.post('/settings.game_topic_category/add', params)

// 修改分类状态
export const apiGameTopicCategoryIsShow = (params: any) =>
  request.post('/settings.game_topic_category/isShow', params)
/**题目分类*/

// S 答题设置

// 答题设置详情
export const apiGameConfigDetails = (params: any) =>
  request.get('/settings.platform/getGameConfig', { params })

// 答题设置保存
export const apiGameConfigSet = (params: any) =>
  request.post('/settings.platform/setGameConfig', params)

// E 答题设置


// ping黑库列表
export const apiGamePingBlackLists = (params: any) =>
  request.get('/settings.game_ping_black/lists', { params })

// 删除ping黑库
export const apiGamePingBlackDelete = (params: any) =>
  request.post('/settings.game_ping_black/delete', params)

// 获取ping黑库详情
export const apiGamePingBlackDetail = (params: any) =>
  request.get('/settings.game_ping_black/detail', { params })

// 编辑ping黑库
export const apiGamePingBlackEdit = (params: any) =>
  request.post('/settings.game_ping_black/edit', params)

// 添加ping黑库
export const apiGamePingBlackAdd = (params: any) => request.post('/settings.game_ping_black/add', params)

// 修改ping黑库状态
export const apiGamePingBlackIsShow = (params: any) =>
  request.post('/settings.game_ping_black/isShow', params)




// ping列表
export const apiGamePingLists = (params: any) =>
  request.get('/settings.game_ping/lists', { params })

// 删除ping
export const apiGamePingDelete = (params: any) =>
  request.post('/settings.game_ping/delete', params)

// 获取ping详情
export const apiGamePingDetail = (params: any) =>
  request.get('/settings.game_ping/detail', { params })

// 编辑ping
export const apiGamePingEdit = (params: any) =>
  request.post('/settings.game_ping/edit', params)

// 添加ping
export const apiGamePingAdd = (params: any) => request.post('/settings.game_ping/add', params)

// 修改ping状态
export const apiGamePingIsShow = (params: any) =>
  request.post('/settings.game_ping/isShow', params)


// app黑库列表
export const apiGameCheatsLists = (params: any) =>
  request.get('/settings.game_cheats/lists', { params })

// 删除app黑库
export const apiGameCheatsDelete = (params: any) =>
  request.post('/settings.game_cheats/delete', params)

// 获取app黑库详情
export const apiGameCheatsDetail = (params: any) =>
  request.get('/settings.game_cheats/detail', { params })

// 编辑app黑库
export const apiGameCheatsEdit = (params: any) =>
  request.post('/settings.game_cheats/edit', params)

// 添加app黑库
export const apiGameCheatsAdd = (params: any) => request.post('/game.game_cheats/add', params)

// 修改app黑库状态
export const apiGameCheatsIsShow = (params: any) =>
  request.post('/settings.game_cheats/isShow', params)

// 获取app黑库分类
export const apiGameCheatsCategoryList = (params: any) =>
  request.post('/settings.game_cheats/categoryList', params)

// app黑库类型列表
export const apiGameCheatsCategoryAllLists = (params: any) =>
  request.get('/settings.game_cheats_category/getAll', { params })


// app黑库分类列表
export const apiGameCheatsCategoryLists = (params: any) =>
  request.get('/settings.game_cheats_category/lists', { params })

// 删除app黑库分类
export const apiGameCheatsCategoryDelete = (params: any) =>
  request.post('/settings.game_cheats_category/delete', params)

// 获取app黑库分类详情
export const apiGameCheatsCategoryDetail = (params: any) =>
  request.get('/settings.game_cheats_category/detail', { params })

// 编辑app黑库分类
export const apiGameCheatsCategoryEdit = (params: any) =>
  request.post('/settings.game_cheats_category/edit', params)

// 添加app黑库分类
export const apiGameCheatsCategoryAdd = (params: any) => request.post('/settings.game_cheats_category/add', params)

// 修改app黑库分类状态
export const apiGameCheatsCategoryIsShow = (params: any) =>
  request.post('/settings.game_cheats_category/isShow', params)



// 无障碍黑库列表
export const apiGameAccessibilityLists = (params: any) =>
  request.get('/settings.game_accessibility/lists', { params })

// 删除无障碍黑库
export const apiGameAccessibilityDelete = (params: any) =>
  request.post('/settings.game_accessibility/delete', params)

// 获取无障碍黑库详情
export const apiGameAccessibilityDetail = (params: any) =>
  request.get('/settings.game_accessibility/detail', { params })

// 编辑无障碍黑库
export const apiGameAccessibilityEdit = (params: any) =>
  request.post('/settings.game_accessibility/edit', params)

// 添加无障碍黑库
export const apiGameAccessibilityAdd = (params: any) => request.post('/settings.game_accessibility/add', params)

// 修改无障碍黑库状态
export const apiGameAccessibilityIsShow = (params: any) =>
  request.post('/settings.game_accessibility/isShow', params)


// ip黑名单列表
export const apiGameBlackIpLists = (params: any) =>
  request.get('/settings.game_black_ip/lists', { params })

// 删除ip黑名单
export const apiGameBlackIpDelete = (params: any) =>
  request.post('/settings.game_black_ip/delete', params)

// 获取ip黑名单详情
export const apiGameBlackIpDetail = (params: any) =>
  request.get('/settings.game_black_ip/detail', { params })

// 编辑ip黑名单
export const apiGameBlackIpEdit = (params: any) =>
  request.post('/settings.game_black_ip/edit', params)

// 添加ip黑名单
export const apiGameBlackIpAdd = (params: any) => request.post('/settings.game_black_ip/add', params)

// 修改ip黑名单状态
export const apiGameBlackIpDisable = (params: any) =>
  request.post('/settings.game_black_ip/disable', params)



// 提现账户黑名单列表
export const apiGameBlackWithdrawalAccountLists = (params: any) =>
  request.get('/settings.game_black_withdrawal_account/lists', { params })

// 删除提现账户黑名单
export const apiGameBlackWithdrawalAccountDelete = (params: any) =>
  request.post('/settings.game_black_withdrawal_account/delete', params)

// 获取提现账户黑名单详情
export const apiGameBlackWithdrawalAccountDetail = (params: any) =>
  request.get('/settings.game_black_withdrawal_account/detail', { params })

// 编辑提现账户黑名单
export const apiGameBlackWithdrawalAccountEdit = (params: any) =>
  request.post('/settings.game_black_withdrawal_account/edit', params)

// 添加提现账户黑名单
export const apiGameBlackWithdrawalAccountAdd = (params: any) => request.post('/settings.game_black_withdrawal_account/add', params)

// 修改提现账号状态
export const apiGameBlackWithdrawalAccountIsShow = (params: any) =>
  request.post('/settings.game_black_withdrawal_account/isShow', params)


// hash黑名单列表
export const apiGameBlackHashLists = (params: any) =>
  request.get('/settings.game_black_hash/lists', { params })

// 删除hash黑名单
export const apiGameBlackHashDelete = (params: any) =>
  request.post('/settings.game_black_hash/delete', params)

// 获取hash黑名单详情
export const apiGameBlackHashDetail = (params: any) =>
  request.get('/settings.game_black_hash/detail', { params })

// 编辑hash黑名单
export const apiGameBlackHashEdit = (params: any) =>
  request.post('/settings.game_black_hash/edit', params)

// 添加hash黑名单
export const apiGameBlackHashAdd = (params: any) => request.post('/settings.game_black_hash/add', params)

// 修改hash黑库状态
export const apiGameBlackHashIsShow = (params: any) =>
  request.post('/settings.game_black_hash/isShow', params)


// 设备ID黑名单列表
export const apiGameBlackDeviceIdLists = (params: any) =>
  request.get('/settings.game_black_device_id/lists', { params })

// 删除设备ID黑名单
export const apiGameBlackDeviceIdDelete = (params: any) =>
  request.post('/settings.game_black_device_id/delete', params)

// 获取设备ID黑名单详情
export const apiGameBlackDeviceIdDetail = (params: any) =>
  request.get('/settings.game_black_device_id/detail', { params })

// 编辑设备ID黑名单
export const apiGameBlackDeviceIdEdit = (params: any) =>
  request.post('/settings.game_black_device_id/edit', params)

// 添加设备ID黑名单
export const apiGameBlackDeviceIdAdd = (params: any) => request.post('/settings.game_black_device_id/add', params)

// 修改设备ID黑库状态
export const apiGameBlackDeviceIdIsShow = (params: any) =>
  request.post('/settings.game_black_device_id/isShow', params)


// ip归属地黑名单列表
export const apiGameBlackIpAddressLists = (params: any) =>
  request.get('/settings.game_black_ip_address/lists', { params })

// ip归属地黑名单
export const apiGameBlackIpAddressDelete = (params: any) =>
  request.post('/settings.game_black_ip_address/delete', params)

// ip归属地黑名单详情
export const apiGameBlackIpAddressDetail = (params: any) =>
  request.get('/settings.game_black_ip_address/detail', { params })

// ip归属地黑名单
export const apiGameBlackIpAddressEdit = (params: any) =>
  request.post('/settings.game_black_ip_address/edit', params)

// ip归属地黑名单
export const apiGameBlackIpAddressAdd = (params: any) => request.post('/settings.game_black_ip_address/add', params)

// ip归属地黑库状态
export const apiGameBlackIpAddressIsShow = (params: any) =>
  request.post('/settings.game_black_ip_address/isShow', params)


// 微信用户黑名单列表
export const apiGameBlackWxUserLists = (params: any) =>
  request.get('/settings.game_black_wx_user/lists', { params })

// 微信用户黑名单
export const apiGameBlackWxUserDelete = (params: any) =>
  request.post('/settings.game_black_wx_user/delete', params)

// 微信用户黑名单详情
export const apiGameBlackWxUserDetail = (params: any) =>
  request.get('/settings.game_black_wx_user/detail', { params })

// 微信用户黑名单
export const apiGameBlackWxUserEdit = (params: any) =>
  request.post('/settings.game_black_wx_user/edit', params)

// 微信用户黑名单
export const apiGameBlackWxUserAdd = (params: any) => request.post('/settings.game_black_wx_user/add', params)

// 微信用户黑库状态
export const apiGameBlackWxUserIsShow = (params: any) =>
  request.post('/settings.game_black_wx_user/isShow', params)



// 微信昵称黑名单列表
export const apiGameBlackWxNicknameLists = (params: any) =>
  request.get('/settings.game_black_wx_nickname/lists', { params })

// 微信昵称黑名单
export const apiGameBlackWxNicknameDelete = (params: any) =>
  request.post('/settings.game_black_wx_nickname/delete', params)

// 微信昵称黑名单详情
export const apiGameBlackWxNicknameDetail = (params: any) =>
  request.get('/settings.game_black_wx_nickname/detail', { params })

// 微信昵称黑名单
export const apiGameBlackWxNicknameEdit = (params: any) =>
  request.post('/settings.game_black_wx_nickname/edit', params)

// 微信昵称黑名单
export const apiGameBlackWxNicknameAdd = (params: any) => request.post('/settings.game_black_wx_nickname/add', params)

// 微信昵称黑库状态
export const apiGameBlackWxNicknameIsShow = (params: any) =>
  request.post('/settings.game_black_wx_nickname/isShow', params)





