
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  apiGameAccessibilityDetail,
  apiGameAccessibilityEdit,
  apiGameAccessibilityAdd
} from '@/api/setting'
@Component({
    components: {}
})
export default class GameAccessibilityEdit extends Vue {
    @Prop() aid?: any
    @Prop() cid?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    categoryList: Array<object> = []
    $refs!: {
        formRef: any
    }
    form = {
        name: '',
        package_name: '',
        service_name: '',
        is_show: 1, // 是否显示 0-不显示 1-显示

    }

    // 表单验证
    formRules = {
      name: [
            {
                required: true,
                message: '请输入应用名',
                trigger: 'blur'
            }
        ],
      package_name: [
        {
          required: true,
          message: '请输入包名',
          trigger: 'blur'
        }
      ],
        is_show: [
            {
                required: true,
                message: '请选择状态',
                trigger: 'change'
            }
        ]
    }
    /** E Data **/

    // 分类详情
    getCategoryDetail() {
        apiGameAccessibilityDetail({
            aid: this.aid,
            id: this.cid
        })
            .then((res: any) => {
                this.form = res
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }


    // 新增分类
    onCategoryAdd() {
        apiGameAccessibilityAdd({
            ...this.form,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑分类
    onCategoryEdit() {
        apiGameAccessibilityEdit({
            ...this.form,
            id: this.cid,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.cid) {
                this.onCategoryEdit()
            } else {
                this.onCategoryAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true

        if (this.cid) {
            this.getCategoryDetail()
        }
    }

    // 关闭弹窗
    close() {
        this.visible = false

        this.form = {
            name: '',
            package_name: '',
            service_name: '',
            is_show: 1, // 是否显示 0-不显示 1-显示

        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
